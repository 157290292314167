<template>
  <b-row>
    <b-col cols="12">
      <b-card>
        <title-text />
        <content-text />
        <ordering />
      </b-card>
    </b-col>
    <b-col cols="12">
      <logo-card />
    </b-col>
    <b-col cols="12">
      <b-button
        variant="primary"
        :disabled="itemData.submitStatus"
        @click="submitForm"
      >
        Kaydet
      </b-button>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCard, BCol, BRow, BButton,
} from 'bootstrap-vue'

import TitleText from '@/views/Admin/Memberships/elements/Title.vue'
import ContentText from '@/views/Admin/Memberships/elements/Content.vue'
import Ordering from '@/views/Admin/Memberships/elements/Ordering.vue'
import LogoCard from '@/views/Admin/Memberships/elements/LogoCard.vue'

export default {
  name: 'ItemForm',
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    TitleText,
    ContentText,
    Ordering,
    LogoCard,
  },
  props: {
    submitForm: {
      type: Function,
      required: true,
    },
  },
  computed: {
    itemData() {
      return this.$store.getters['memberships/dataItem']
    },
  },
}
</script>
