<template>
  <b-card title="Logo / Sembol">
    <b-row>
      <b-col
        v-if="dataItem.image"
        cols="12"
        md="auto"
        class="text-center"
      >
        <div class="border-1 bordered bg-light p-2 rounded">
          <b-img
            :src="baseURL + '/media/memberships/' + dataItem.image"
            fluid-grow
          />
        </div>
        <b-button
          class="mt-1"
          variant="danger"
          pill
          size="sm"
          @click="removeLogo"
        >
          <FeatherIcon icon="TrashIcon" /> Görseli Sil
        </b-button>
      </b-col>
      <b-col>
        <b-form-group
          label="Dosya Yükle"
          label-for="logo-file"
        >
          <b-form-file
            id="logo-file"
            v-model="upload_logo"
            placeholder="Bir görsel dosyası seçin veya buraya bırakın..."
            drop-placeholder="görsel dosyasını buraya bırakın..."
            browse-text="Dosya Seçin"
            :disabled="!!dataItem.image"
          />
        </b-form-group>
        <b-button
          variant="success"
          :disabled="!upload_logo"
          @click="uploadLogo"
        >
          Dosya Yükle
        </b-button>
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
import {
  BCard, BRow, BCol, BImg, BFormGroup, BFormFile, BButton,
} from 'bootstrap-vue'

export default {
  name: 'LogoCard',
  components: {
    BCard, BRow, BCol, BImg, BFormGroup, BFormFile, BButton,
  },
  data() {
    return {
      baseURL: this.$store.state.app.baseURL,
      upload_logo: null,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['memberships/dataItem']
    },
  },
  methods: {
    removeLogo() {
      this.$store.dispatch('cropperUploads/removeData', {
        filename: this.dataItem.image,
        component: 'memberships',
        params: 'memberships',
      })
        .then(response => {
          if (response.status) {
            this.dataItem.image = null
            this.dataItem.thumb = null
            this.dataItem.image_webp = null
            this.dataItem.thumb_webp = null
          }
        })
    },
    uploadLogo() {
      this.$store.dispatch('cropperUploads/uploadImage', {
        folder: 'memberships',
        resize: false,
        width: null,
        thumb: false,
        webp: false,
        ordering: 1,
        component: 'memberships',
        params: 'memberships',
        item_id: this.dataItem.id,
        upload_image: this.upload_logo,
        filename: 'memberships-logo.png',
      })
        .then(response => {
          if (response.status) {
            this.dataItem.mediaData = response.data
            this.dataItem.image = response.data.filename
            this.dataItem.thumb = response.data.thumb
            this.dataItem.image_webp = response.data.filename_webp
            this.dataItem.thumb_webp = response.data.thumb_webp
          }
        })
    },
  },
}
</script>
